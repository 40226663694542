header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

.cta {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.header_socials {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_socials::after {
    margin-top: 1rem;
    content: "";
    width: 1px;
    height: 3rem;
    background: var(--color-primary);
}

.header_socials a:hover {
    transform: scale(1.5) rotate(8deg);
}

.socials {
    color: var(--color-primary);
    transform: rotate(90deg);
    margin-bottom: 2.5rem;
    letter-spacing: 1px;
    transition: transform 0.4s;
}

.socials:hover {
    transform: scale(1.1) rotate(90deg);
    cursor: pointer;
}

.me {
    border-bottom: 0.1rem solid var(--color-primary);
    border-bottom-left-radius: 5rem;
    background: transparent;
    width: 20rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    overflow: hidden;
}

.letterT{
    opacity: 0.4;
    width: 18rem;
    position: absolute;
    left: calc(45% - 11rem);
    margin-top: 9rem;
    z-index: -1;
    overflow: hidden;
    filter: brightness(0.6) drop-shadow(2rem 1rem 1rem #4444dd) blur(0.5rem);
}

.letterZ{
    opacity: 0.4;
    width: 16rem;
    position: absolute;
    left: calc(65% - 11rem);
    margin-top: 10rem;
    z-index: -1;
    overflow: hidden;
    filter: drop-shadow(2rem 1rem 1rem #4444dd) opacity(0.9) blur(0.3rem);
}


.scroll_down {
    position: absolute;
    right: 2.3rem;
    bottom: 6rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.arrow {
    position: absolute;
    margin-left: 1.5rem;
    transform: rotate(-90deg);
}

.scroll_down:hover {
    transform: scale(1.1) rotate(90deg);
}


/* ==========  MEDIA QUERIES (MEDIUM DEVICES, TABLETS) ====== */

@media screen and (max-width: 1024px){
    header {
        height: 80vh;
    }
}

/* ==========  MEDIA QUERIES (SMALL DEVICES, PHONES) ====== */

@media screen and (max-width: 600px){
    header {
        height: 100vh;
    }
    .header_socials, .scroll_down {
        display: none;
    }
}