.container.contact_container {
    width: 48%;
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
}

.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact_option {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1.2rem;
    text-align: center;
    padding: 1.2rem;
    transition: var(--transition);
}

.contact_option:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.contact_option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a {
    margin-top: 0.7rem;
    font-size: 0.8rem;
    display: inline-block;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: white;
}

textarea:valid {
    border-color: lawngreen;
    background-color: transparent;
}

input:valid {
    background-color: transparent;
    border-color: lawngreen;
}

input::placeholder{
    color: var(--color-light);
}

textarea::placeholder {
    color: var(--color-light);
}


/* ==========  MEDIA QUERIES (MEDIUM DEVICES, TABLETS) ====== */

@media screen and (max-width: 1024px) {
        .container.contact_container {
            grid-template-columns: 1fr;
        }
    }

/* ==========  MEDIA QUERIES (SMALL DEVICES, PHONES) ====== */

@media screen and (max-width: 600px) {
    .container.contact_container {
        width: var(--container-width-sm);
    }
}