@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: linear-gradient(to bottom right, #1d1960,#4377c6,#252150);
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all 0.2s ease;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}

.circles{
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    background: var(--color-primary);
    color: white;
    box-shadow: inset -5px 5px 10px #53c2c8,
                inset 5px -5px 10px #5160b8a9;
    width: 20px;
    height: 20px;
    animation: animate 25s linear infinite;
    bottom: -150px;
    text-align: center;
    opacity: 0.5;
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    padding-top: 25px;
    animation-delay: 0s;
}

.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 55px;
    height: 55px;
    animation-delay: 4s;
    padding-top: 15px;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
    padding-top: 20px;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(180deg);
        opacity: 0;
        border-radius: 0;
    }

    50%{
        transform: translateY(-500px) rotate(360deg);
        opacity: 0.5;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0.05;
        border-radius: 50%;
    }

}

/* =============  GENERAL STYLES ========= */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    height: 100vh;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

h2, h5 {
    color: var(--color-light);
}

h2 {
    color: var(--color-primary);
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

.desc {
    background-color: var(--color-bg-variant);
    color: #98407b;
    font-size: medium;
    margin-left: 1rem;
}

.dot1 {
    margin-right: 5px;
    margin-left: 15px;
    font-size: 24px;
    animation: blink1 2s infinite;
}

@keyframes blink1 {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

.dot2 {
    margin-right: 5px;
    font-size: 24px;
    animation: blink2 2s infinite 0.2s;
}

@keyframes blink2 {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

.dot3 {
    margin-right: 5px;
    font-size: 24px;
    animation: blink3 2s infinite 0.4s;
}

@keyframes blink3 {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

.dot4 {
    margin-right: 5px;
    font-size: 24px;
    animation: blink3 2s infinite 0.6s;
}

@keyframes blink4 {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

.dot5 {
    margin-right: 5px;
    font-size: 24px;
    animation: blink3 2s infinite 0.8s;
}

@keyframes blink5 {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background-color: transparent;
    color: var(--color-bg);
    border-color: #fff;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}


/* ==========  MEDIA QUERIES (MEDIUM DEVICES, TABLETS) ====== */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        height: 150vh;
    }

    
}

/* ==========  MEDIA QUERIES (SMALL DEVICES, PHONES) ====== */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}

