#portfolio {
    margin: 2rem;
}

#portfolio > h5 {
    margin-top: 5rem;
    margin-bottom: 1rem;
    text-align: center;
}

#portfolio > h2 {
    margin-bottom: 2.5rem;
    text-align: center;
} 


h4 {
    margin: 1rem auto 1.5rem auto;
    width: 85%;
    opacity: 75%;
}

.portfolio_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio_item{
    position: relative;
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1rem;
    transition: var(--transition);
}

.portfolio_item img {
    transition: transform 0.5s;
}

.portfolio_item img:hover {
    transform: scale(1.02);
}


.portfolio_item h3 {
    margin: 1rem 0 1rem;
    text-align: center;
}

.portfolio_item-cta {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

/* ==========  MEDIA QUERIES (MEDIUM DEVICES, TABLETS) ====== */

@media screen and (max-width: 1024px){
    
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
        margin-top: 2rem;
    }

}

/* ==========  MEDIA QUERIES (SMALL DEVICES, PHONES) ====== */

@media screen and (max-width: 700px){

    

    .portfolio_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}