#about {
    margin: 2rem;
}

#about > h5 {
    margin-top: 5rem;
    margin-bottom: 1rem;
    text-align: center;
}

#about > h2 {
    margin-bottom: 2.5rem;
    text-align: center;
}

.about_container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 5%;
    margin-bottom: 5rem;
}

.about_me {
    margin-left: 3rem;
    width: 75%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about_me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(5deg);
    transition: var(--transition);
}

.about_me-image:hover {
    transform: rotate(0);
}

.about_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about_card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    text-align: center;
    padding: 2rem;
    transition: var(--transition);
    
}

.about_card:hover {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.556);
    cursor: default;
}

.about_icon {
    color: var(--color-primary);
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.about_icon:hover {
    transform: rotate(8deg);
}

.about_card h5 {
    font-size: 1rem;
}

.about_card small {
    font-size: 0.75rem;
    color: var(--color-light);
}

.about_content p {
    color: var(--color-light);
    margin: 2rem 0 2rem; 
}

/* ==========  MEDIA QUERIES (MEDIUM DEVICES, TABLETS) ====== */

@media screen and (max-width: 1024px){
    .about_container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about_me {
        width: 50%;
        margin: 2rem auto 4rem;
    }
}

/* ==========  MEDIA QUERIES (SMALL DEVICES, PHONES) ====== */

@media screen and (max-width: 600px){

    #about {
        margin-top: 1rem;
    }

    .about_me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about_cards {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .about_card {
        margin: 0 2rem
    }

    .about_content p {
        margin: 1.5rem 2rem;
    }
}